import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { Layout } from '../components';
import PostPreview from '../components/PostPreview';
import Section from '../components/Section';
import SectionTitle from '../components/SectionTitle';


const Paragraph = styled.p`
  text-align: center;
`;

const Row = styled.div`
  margin-bottom: 16px;
`;

const Category = (props) => {
  const {
    pageContext: { category },
    data: {
      posts: { nodes }
    }
  } = props;

  const rows = [];
  nodes.forEach((value, idx) => {
    const rowIdx = Math.floor(idx/3);
    rows[rowIdx] = (rows[rowIdx] || []).concat(value)
  });

  return (
    <Layout>
      <Section featured>
        <SectionTitle as='h1' centered>{category.data.name}</SectionTitle>
        {
          category.data.description.text &&
          <Paragraph>{category.data.description.text}</Paragraph>
        }
      </Section>
      <Section>
        {
          rows && rows.map((rowItems) => (
            <Row>
              {
                rowItems && rowItems.map((post) => (
                  <PostPreview node={post} invert />
                ))
              }
            </Row>
          ))
        }
      </Section>
    </Layout>
  );
};

export default Category;

Category.propTypes = {
  pageContext: PropTypes.shape({
    category: PropTypes.object.isRequired
  }).isRequired,
  data: PropTypes.shape({
    posts: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
      totalCount: PropTypes.number.isRequired
    }).isRequired
  }),
  location: PropTypes.object.isRequired
};

export const pageQuery = graphql`
  query CategoryPage($uid: String!) {
    posts: allPrismicPost(
      sort: { fields: [data___date], order: DESC }
      filter: {
        data: {categories: {elemMatch: {category: {uid: {eq: $uid}}}}}
      }
    ) {
      totalCount
      nodes {
        uid
        data {
          title {
            text
          }
          date(formatString: "DD.MM.YYYY")
          categories {
            category {
              document {
                data {
                  name
                }
              }
            }
          }
          titleImage: title_image {
            alt
            localFile {
              childImageSharp {
                fluid(maxWidth: 496) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
